window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;

    $ = global.$ = global.jQuery = require('jquery');

    require('bootstrap');
    $.modal = require('bootstrap/js/dist/modal');
    require('bootstrap-select');
    require('@fortawesome/fontawesome-free');

    window.ClipboardJS = require('clipboard');

    $.fn.selectpicker.Constructor.BootstrapVersion = '4';


    require('datatables.net-bs4');
    //require('datatables.net-buttons-bs4');

    //window.buttons = $.buttons = require( 'datatables.net-buttons' );

    require('datatables.net-buttons-bs4');
    require('datatables.net-buttons/js/dataTables.buttons');
    require('datatables.net-buttons/js/buttons.html5');
    require('datatables.net-buttons/js/buttons.print');
    require('datatables.net-buttons/js/buttons.colVis');

    window.moment = require('moment');
    $.datetimepicker = window.$.datetimepicker = window.datetimepicker = require('tempusdominus-bootstrap-4');

    window.JSZip = require('jszip');

    window.pdfMake = require('pdfmake/build/pdfmake');
    window.vfs = vfs_fonts = require('pdfmake/build/vfs_fonts');
    window.pdfMake.vfs = vfs_fonts.pdfMake.vfs;

} catch (e) {

}


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
/*
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');


window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true
});
*/
