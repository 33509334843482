require('./bootstrap');

import $ from 'jquery';

import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css';

window.$ = window.jQuery = $;
$( document ).ready(function() {
    $('[data-clipboard-target]')
        .tooltip({
            title:  $('[data-clipboard-target]').attr('data-trans-copy'),
            placement: 'left',
        });

    var clipboard = new ClipboardJS('[data-clipboard-target]');

    clipboard.on('success', function(e) {
        e.clearSelection();
        $(e.trigger)
            .attr('data-original-title', $(e.trigger).attr('data-trans-copied'))
            .tooltip('show')
            .blur()
            .attr('data-original-title', $(e.trigger).attr('data-trans-copy'))
    });

    clipboard.on('error', function(e) {
        var modifierKey = /Mac/i.test(navigator.userAgent) ? '\u2318' : 'Ctrl-'
        var fallbackMsg = 'Press ' + modifierKey + 'C to copy'
        $(e.trigger)
            .attr('data-original-title', fallbackMsg)
            .tooltip('show')
            .blur()
            .attr('data-original-title', $(e.trigger).attr('data-trans-copy'))
    });

    window.datatable = $('[data-table]').DataTable({
        dom: "<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4 text-center'B><'col-sm-12 col-md-4'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
            'excel',
            'pdf',
            'print'
        ]
    });
    window.$.DataTable = $().DataTable();

    window.$.modal = $().modal();

    $('#confirm-delete').on('show.bs.modal', function(e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });

    $('#confirm-moderator').on('show.bs.modal', function(e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
    $(".summernote").summernote({
        height: 150,   //set editable area's height,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['forecolor']],
        ['para', ['ul', 'ol']],
        ['codeview']
        ],

    });
    $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
        format: 'YYYY-MM-DD HH:mm:ss',
        icons: {
            time: 'fas fa-clock',
            date: 'fas fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'far fa-calendar-check',
            clear: 'fas fa-trash',
            close: 'fas fa-times'
        } });
    $('#datetimepicker1').datetimepicker({
        'locale':'lv'
    });
    $('#datetimepicker2').datetimepicker({
        'locale':'lv'
    });
});
